import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { Box, CircularProgress, Snackbar, Modal, Button } from "@mui/material";

import moment from "moment";

import DeleteModal from "../../components/Modals/DeleteModal/DeleteModal";
import ListTable from "../../components/ListTable/ListTabel";

import { deleteList, getList } from "../../services/DataService";

import { Alert } from "../../utils/alert";

import SaveListModal from "./SaveListModal";
import { useListsAPI } from "../../hooks/useListsAPI";
import CheckStatus from "../../components/CheckStatus/CheckStatus";

const ListsPage = () => {
  const {
    listsData: { data, isLoading, isError },
  } = useListsAPI();

  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [defaultValues, setDefaultValues] = useState(null);
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "List created successfully",
  });

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const Delete = useMutation(deleteList, {
    onSuccess: (data) => {
      handleOpenAlert("success", "List deleted successfully");
    },
    onError: () => {
      handleOpenAlert("error", "Oops, something went wrong");
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  const handleOpenSaveModal = () => setShowSaveModal(true);
  const handleCloseSaveModal = () => setShowSaveModal(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const redirectAddNew = () => {
    setDefaultValues(null);
    handleOpenSaveModal();
  };

  const handleOpenDeleteModal = (list) => {
    setDeleteId({ id: list.id, name: list.name });
    setShowDeleteModal(true);
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };

  const redirectIdList = async (list) => {
    const data = await getList(list.id);
    setDefaultValues(data);
    handleOpenSaveModal();
  };

  const openIdList = (list) => {
    navigate(`/lists/${list.id}`);
  };

  const handleDeleteList = (list) => {
    handleCloseDeleteModal();
    Delete.mutate(deleteId.id);
  };

  const headers = [
    {
      lable: "List Name",
      align: "left",
    },
    {
      lable: "Global",
      align: "left",
    },
    {
      lable: "Created at",
      align: "right",
    },
    {
      lable: "",
      align: "left",
    },
  ];
  const columns = [
    {
      accessor: (element) => element["name"],
      Cell: (event, list) => {},
      align: "left",
    },
    {
      accessor: (element) => <CheckStatus isChecked={element.global} />,
      Cell: (event, list) => {},
      align: "left",
    },
    {
      accessor: (element) => moment(element.createdAt).format("MM/DD/YY HH:MM"),
      Cell: (event, action) => {},
      align: "left",
    },
  ];

  if (isLoading) {
    return (
      <Box className="wrapper-loading">
        <CircularProgress />
      </Box>
    );
  }
  if (isError) {
    return <h1>Something wrong</h1>;
  }
  return (
    <>
      <Box>
        <Box sx={{ m: "20px", textAlign: "end" }}>
          <Button onClick={redirectAddNew} variant="addNew" size="large">
            New List
          </Button>
        </Box>
        <Box className="wrapper-source-list" sx={{ padding: "24px" }}>
          <ListTable headers={headers} data={data} openId={openIdList} handleDelete={handleOpenDeleteModal} redirectId={redirectIdList} columns={columns} />
        </Box>
        <Snackbar open={alert.isShow} autoHideDuration={3500} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={handleCloseAlert}>
          <Alert onClose={handleCloseAlert} severity={alert.type} sx={{ width: "100%" }}>
            {alert.message}
          </Alert>
        </Snackbar>
      </Box>
      <Modal open={showSaveModal} onClose={handleCloseSaveModal} sx={{ backdropFilter: "blur(14px)" }}>
        <SaveListModal handleCloseSaveModal={handleCloseSaveModal} defaultValues={defaultValues} />
      </Modal>
      <Modal open={showDeleteModal} onClose={handleCloseDeleteModal} sx={{ backdropFilter: "blur(14px)" }}>
        <DeleteModal handleCloseDeleteModal={handleCloseDeleteModal} handleDelete={handleDeleteList} text={"List"} name={deleteId?.name} />
      </Modal>
    </>
  );
};

export default ListsPage;
