import { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAppState } from "../contexts/AppContext";
import { useLogout } from "./useLogout";
import { editLists, getLists, postLists } from "../services/DataService";

export function useListsAPI(handleCloseSaveModal) {
  const { handleOpenAlert } = useAppState();
  const { logout } = useLogout();
  const queryClient = useQueryClient();
  const listsData = useQuery(["getLists"], getLists);

  const postList = useMutation(postLists, {
    onSuccess: (data) => {
      handleOpenAlert("success", "List created successfully");
      setTimeout(() => {
        handleCloseSaveModal();
      }, [1000]);
    },
    onError: (err) => {
      if (err.response.status === 401) {
        logout();
      }
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });
  const editList = useMutation(editLists, {
    onSuccess: (data) => {
      handleOpenAlert("success", "List created successfully");
      setTimeout(() => {
        handleCloseSaveModal();
      }, [1000]);
    },
    onError: (err) => {
      if (err.response.status === 401) {
        logout();
      }
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });

  return useMemo(
    () => ({
      postList,
      editList,
      listsData,
    }),
    [postList, editList, listsData]
  );
}
