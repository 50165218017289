import React, { useState, useEffect } from "react";
import { Box, TextField, Typography, Switch, Snackbar } from "@mui/material";
import CancelButton from "../../components/SaveModalButtons/CancelButton";
import SaveButton from "../../components/SaveModalButtons/SaveButton";
import EditButton from "../../components/SaveModalButtons/EditButton";
import { Alert } from "../../utils/alert";
import style from "./styles.module.css";
import ".././style.css";
import { useListsAPI } from "../../hooks/useListsAPI";
import useEscapeClose from "../../hooks/CloseModal/CloseModal";

const SaveListModal = ({ handleCloseSaveModal, defaultValues }) => {
  const [name, setName] = useState("");
  const [checked, setChecked] = useState(false);
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "List created successfully",
  });

  useEffect(() => {
    if (defaultValues) {
      setName(defaultValues.name);
      setChecked(defaultValues.global);
    }
  }, [defaultValues]);

  useEscapeClose(handleCloseSaveModal);

  // todo: improve
  const requiredFields = [name];

  const validation = () => !requiredFields.every((i) => i);

  const EditValidation = () => {
    if (defaultValues.name === name && defaultValues.global === checked) {
      return true;
    } else {
      return false;
    }
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  const { postList, editList } = useListsAPI(handleCloseSaveModal);

  const handlePostList = () => {
    try {
      postList.mutate({
        name: name,
        global: checked,
      });
    } catch (error) {
      handleOpenAlert("error", "Invalid JSON schema in config field");
    }
  };
  const handleEditList = () => {
    try {
      editList.mutate({
        data: {
          name: name,
          global: checked,
        },
        id: defaultValues.id,
      });
    } catch (error) {
      handleOpenAlert("error", "Invalid JSON schema in config field");
    }
  };

  return (
    <>
      <Box className={style.modal}>
        <Box>
          <Typography variant={"h1"} className={style.title}>
            {defaultValues ? "Edit" : "Create"} List
          </Typography>
          <Box className={style.create_source_item}>
            <Typography variant={"subtitle2"}>Name</Typography>
            <TextField placeholder={"Enter name"} value={name} onChange={handleChangeName} variant="outlined" />
          </Box>
          <Box className={style.create_source_item}>
            <Box className={style.switch}>
              <Switch checked={checked} onChange={handleChangeChecked} />
              <Typography sx={{ margin: "-2px 0 0 10px" }}>Global</Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", gap: "20px", margin: "20px 0 20px 0" }}>
            <CancelButton handleCloseSaveModal={handleCloseSaveModal} />
            {defaultValues ? <EditButton handlePost={handleEditList} validation={EditValidation} /> : <SaveButton handlePost={handlePostList} validation={validation} />}
          </Box>
        </Box>
      </Box>
      <Snackbar open={alert.isShow} autoHideDuration={3500} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alert.type} sx={{ width: "100%" }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SaveListModal;
