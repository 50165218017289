import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Snackbar,
} from "@mui/material";
import CancelButton from "../../components/SaveModalButtons/CancelButton";
import SaveButton from "../../components/SaveModalButtons/SaveButton";
import EditButton from "../../components/SaveModalButtons/EditButton";
import { Alert } from "../../utils/alert";
import style from "./styles.module.css";
import ".././style.css";
import { useListItemsAPI } from "../../hooks/useListItemsAPI";
import useEscapeClose from "../../hooks/CloseModal/CloseModal";

const SaveListItemModal = ({ handleCloseSaveModal, defaultValues, listId }) => {

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "List item created successfully",
  });

  useEffect(() => {
    if (defaultValues) {
      setEmail(defaultValues.email);
      setPhone(defaultValues.phone);
    }
  }, [defaultValues]);

  useEscapeClose(handleCloseSaveModal);

  // todo: improve
  const requiredFields = [email, phone];

  const validation = () => !requiredFields.some((i) => i);

  const EditValidation = () => {
    if (
        defaultValues.name === email &&
        defaultValues.phone === phone
    ) {
      return true;
    }

    if (email === "" && phone === "") {
        return true;
    }

    return false;
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangePhone = (event) => {
    setPhone(event.target.value);
  }

  const { postListItem, editListItem } = useListItemsAPI({ listId, handleCloseSaveModal });

  const handlePostList = () => {
    try {
      postListItem.mutate({
        listId,
        data: {
          email: email || undefined,
          phone: phone || undefined,
        },
      });
    } catch (error) {
      handleOpenAlert("error", "Invalid JSON schema in config field");
    }
  };
  const handleEditList = () => {
    try {
      editListItem.mutate({
        listId,
        itemId: defaultValues.id,
        data: {
          email: email || undefined,
          phone: phone || undefined,
        },
      });
    } catch (error) {
      handleOpenAlert("error", "Invalid JSON schema in config field");
    }
  };

  return (
      <>
        <Box className={style.modal}>
          <Box>
            <Typography variant={"h1"} className={style.title}>
              {defaultValues ? "Edit" : "Create"} List Item
            </Typography>
            <Box className={style.create_source_item}>
              <Typography variant={"subtitle2"}>Email</Typography>
              <TextField
                  placeholder={"Enter email"}
                  value={email}
                  type={"email"}
                  name={"email"}
                  onChange={handleChangeEmail}
                  variant="outlined"
              />
            </Box>
            <Box className={style.create_source_item}>
              <Typography variant={"subtitle2"}>Phone</Typography>
              <TextField
                  placeholder={"Enter phone"}
                  value={phone}
                  name={"phone"}
                  onChange={handleChangePhone}
                  variant="outlined"
              />
            </Box>

            <Box sx={{ display: "flex", gap: "20px", margin: "20px 0 20px 0" }}>
              <CancelButton handleCloseSaveModal={handleCloseSaveModal} />
              {defaultValues ? (
                  <EditButton
                      handlePost={handleEditList}
                      validation={EditValidation}
                  />
              ) : (
                  <SaveButton
                      handlePost={handlePostList}
                      validation={validation}
                  />
              )}
            </Box>
          </Box>
        </Box>
        <Snackbar
            open={alert.isShow}
            autoHideDuration={3500}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            onClose={handleCloseAlert}
        >
          <Alert
              onClose={handleCloseAlert}
              severity={alert.type}
              sx={{ width: "100%" }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </>
  );
};

export default SaveListItemModal;
