import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import HeaderTitle from "./components/Header/Header";

import ActionsPage from "./pages/ActionsPage/ActionsPage";
import CreateWorkflowPage from "./pages/WorkflowsPage/CreateWorkflowsPage";
import DatabasePage from "./pages/StoragePage/DatabasePage";
import Documentation from "./pages/Documentation/Documentation";
import LoginPage from "./pages/LoginPage/LoginPage";
import MatchingDetailsPage from "./pages/StoragePage/MatchingPage/MatchingDetailsPage";
import ModulesPage from "./pages/ModulesPage/ModulesPage";
import NotFoundPage from "./pages/NotFoundPage";
import RequireAuth from "./pages/RequireAuth";
import ApiKeys from "./pages/ApiKeys/ApiKeys";
import StorageCollectionsPage from "./pages/StoragePage/StorageCollectionsPage";
import TargetsPage from "./pages/TargetsPage/TargetsPage";
import UpdateWorkflowPage from "./pages/WorkflowsPage/UpdateWorkflowsInfo";
import WorkflowsPage from "./pages/WorkflowsPage/WorkflowsPage";
import AnalyticsPage from "./pages/AnalyticsPage/AnalyticsPage";
import DashboardsPage from "./pages/DashboardsPage/Dashboards";
import MasterRecordsPage from "./pages/MasterRecords/MasterRecordsPage";
import MasterRecordsDetails from "./pages/MasterRecords/MasterRecordsDetails";
import GroupsPage from "./pages/GroupsPage/GroupsPage";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import ListsPage from "./pages/ListsPage/ListsPage";
import ListItemsPage from "./pages/ListsPage/ListItemsPage";

const routes = () => (
  <Router>
    <Routes>
      <Route
        element={
          <RequireAuth>
            <HeaderTitle />
          </RequireAuth>
        }
      >
        <Route exact path="/" element={<ApiKeys />} />
        <Route path="/dashboards" element={<DashboardsPage />} />
        <Route path="/api-keys" element={<ApiKeys />} />
        <Route path="/databases" element={<DatabasePage />} />
        <Route path="/master-records" element={<MasterRecordsPage />} />
        <Route path="/master-records/:hash" element={<MasterRecordsDetails />} />
        <Route path="/databases/:id" element={<StorageCollectionsPage />} />
        <Route path="/databases/matching/:id" element={<MatchingDetailsPage />} />
        <Route path="/outgoing" element={<TargetsPage />} />
        <Route path="/groups" element={<GroupsPage />} />
        <Route path="/actions" element={<ActionsPage />} />
        <Route path="/workflows" element={<WorkflowsPage />} />
        <Route path="/CreateWorkflows" element={<CreateWorkflowPage />} />
        <Route path="/workflows/:id" element={<UpdateWorkflowPage />} />
        <Route path="/modules" element={<ModulesPage />} />
        <Route path="/analytics" element={<AnalyticsPage />} />
        <Route path="/doc" element={<Documentation />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/lists" element={<ListsPage />} />
        <Route path="/lists/:id" element={<ListItemsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  </Router>
);

export default routes;
