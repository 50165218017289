import {Box, Typography} from "@mui/material";
import styles from "./styles.module.css";
export default function CheckStatus({ isChecked, className }) {
  return (
    <Box className={className}>
      {isChecked ? (
        <Typography variant={'subtitle1'} className={styles.ActiveStatus}>&#10003;</Typography>
      ) : (
        <Typography variant={'subtitle1'} className={styles.InactiveStatus}>-</Typography>
      )}
    </Box>
  );
}
